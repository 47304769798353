import { Page } from "@/components/Page";

import { ErrorPage } from "@fllite/ui/components";
import { Layout } from "@fllite/ui/components";

const Page404 = () => (
  <Layout allowOverflow>
    <Page hasFooter>
      <ErrorPage statusCode={404} />
    </Page>
  </Layout>
);

export default Page404;
